export function Home() {
    return (
      <>
        <div className="flex flex-col items-center justify-center h-screen bg-base-200">
          <div className="avatar placeholder">
            <div className="bg-secondary text-neutral-content rounded-full w-36">
              <span className="text-6xl">NK</span>
            </div>
          </div>
          <div className="text-center mt-20">
            <h1 className="text-3xl font-bold tracking-tight text-secondary sm:text-5xl">
              This website is still a work in progress! It'll be done soon!
            </h1>
            <h1 className="mt-1 text-3xl font-bold tracking-tight text-secondary sm:text-5xl">
              Thanks, Nathan K
            </h1>
          </div>
        </div>
      </>
    );
  }
  
  export default Home;
  