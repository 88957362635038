export function Error() {
    return (
      <>
        <main className="grid min-h-screen place-items-center bg-base-100 px-6 py-24 sm:py-32 lg:px-8 bg-cover bg-center bg-[url('img/bg6.jpg')]">
            <div className="box-content sm:mx-auto sm:w-full sm:max-w-sm py-20 px-20 rounded-box bg-base-100">  
            <div className="text-center">
                <p className="text-base text-3xl sm:text-5xl font-semibold text-primary">404</p>
                <h1 className="mt-4 text-3xl font-bold tracking-tight text-secondary sm:text-5xl">Page not found</h1>
                <div className="mt-10 flex items-center justify-center gap-x-6">
                <button className="btn btn-error btn-xs sm:btn-sm md:btn-md lg:btn-lg">
                    <a
                    href="/"
                    >
                    Go back home
                    </a>
                </button>
                </div>
            </div>
            </div>
        </main>
      </>
    )
  }

export default Error